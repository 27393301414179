import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 56,
  BSCTESTNET = 97
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

// export const FACTORY_ADDRESS = '0x32EC9881f3BC05754410A3e83F3d9527f69963a7' // bsc test
// export const ROUTER_ADDRESS = '0xDBDAF36Ad10821866aA8b7c2ee2Ab0A747C65Eb2' // bsc test
// export const INIT_CODE_HASH = '0xf9c4861b6950cc836c98c2bc598384a544f3a90873244941da939e51cbc73ed2' // bsc test

export const FACTORY_ADDRESS = '0xc801C7980c8C7900Bc898B1F38392b235fF64097' // bsc main
export const ROUTER_ADDRESS = '0x0F4610aB02920a99f639F675085A5d3e24b0D7ae' // bsc main
export const INIT_CODE_HASH = '0xf1d77ba4cb681341af63066d44297a59a8962ec4fe422a20eca5077dca88cef9' // bsc main

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
